import React, { useState, useEffect, useRef } from "react";
import "./Course.scss";
import { FaBars } from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { setCookie } from "react-use-cookie";
import CoursePlayer from "./CoursePlayer";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import UserHeader from "../Header/UserHeader/UserHeader";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import { IconButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Tabs, Button, Box, Tab } from "@mui/material";
import { List, ListItem, Breadcrumbs } from "@material-ui/core";
import ReactPlayer from "react-player";
import useWindowDimensions from "../../modules/Window/Window";
import QuizIc from "../../assets/svgjs/Quiz";
import PdfIcon from "../../assets/svgjs/Pdf";
import ClockGray from "../../assets/svgjs/ClockGray";
import Done from "../../assets/svgjs/Done";
import DoneMUI from "@mui/icons-material/CheckCircleOutline";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import References from "./References";
import { Tab as Tab1 } from "semantic-ui-react";
import Video from "../../assets/svgjs/Video";
import Audio from "../../assets/svgjs/Audio";
import Review from "../../assets/svgjs/Review";
import Html from "../../assets/svgjs/Html";
import Scrom from "../../assets/images/scorm.png";
import Discussion from "./Discussion/Discussion";
import Assessment from "../../modules/Assessment/Assessment";
import Skeleton from "@material-ui/lab/Skeleton";
import Assignment from "../../modules/Assignment/Assignment";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import Fullscreen from "@material-ui/icons/Fullscreen";
import screenfull from "screenfull";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import RecordSession from "./RecordSession";
import FeedBack from "./FeedBack";
import swal from "sweetalert";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Reply from "../../assets/svgjs/Reply";
import Avatar from "@material-ui/core/Avatar";
import DoneIcon from "@mui/icons-material/Done";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ClockOrange from "../../assets/svgjs/ClockOrange";
import SendIcon from "@mui/icons-material/Send";
import OutlinedInput from "@mui/material/OutlinedInput";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import Swal from "sweetalert";
import DirectionsIcon from "@mui/icons-material/Directions";
import ClearIcon from "@mui/icons-material/Clear";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VisibilityIcon from "@mui/icons-material/Visibility";

const ProjectFile = (props) => {
  let { handleFileClose, obj, objId, bcid } = props;

  let userDetails = useSelector(authData);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100% !important",
        height: "84vh",
        overflowX: "hidden",
        paddingBottom: "20px",
        background: "white",
      }}
    >
      <div>
        <div
          style={{
            margin: "0.5rem",
            display: "flex",
            justifyContent: "space-between",
            fontSize: "14px",
          }}
        >
          <p>{obj.fileName} </p>
          <button onClick={handleFileClose}>X</button>
        </div>

        <iframe
          src={`https://${
            Constants.DOMAIN
          }/${config.aws_org_id.toLowerCase()}-data/data/project/${
            userDetails.eid
          }/${bcid}/${objId}/${obj.fileUrlName}`}
          width={"92vw"}
          title="Iframe Example"
          style={{ width: "92vw", height: "74vh", margin: "2rem" }}
        ></iframe>
      </div>
    </div>
  );
};

export default ProjectFile;
