import React from "react";
import "./Course.scss";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import LockIcon from '@mui/icons-material/Lock';
import ClockGray from "../../assets/svgjs/ClockGray";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import Grid from "@material-ui/core/Grid";
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { Collapse, IconButton, Table, Button, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from '@mui/material';

const useStyles = makeStyles({
  formContainer: {
    "@media (max-width: 940px)": {
      width: "100%",
    },
  },
  mainDialog: {
    display: "flex",
    justifyContent: "center",
    width: "90%",
    height: "84vh",
    overflowX: "hidden",
    borderRadius: "5px",
    background: "white",
    "& > div": {
      width: "100%",
    },
  },
  tabLabel: {
    fontSize: "1.8rem!important",
    textTransform: "capitalize!important",
    alignItems: "start!important",
    "& > svg": {
      position: "absolute",
      right: "20px",
      margin: "0!important",
    },
    '&.Mui-selected': {
      color: '#e4651d!important',
      borderBottom: '2px solid #e4651d',
    },
  },

  mainGrid: {
    width: "100%",
    margin: 0,
    "& > div": {
      padding: "15px!important",
    },
  },
  joinNowButton: {
    backgroundColor: "var(--sub-heading-color) !important",
    textTransform: "capitalize!important",
    height: "40px",
    fontSize: "1.5rem!important",
    border: "none",
    color: "white!important",
    borderRadius: "4px",
  },

});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "var(--sub-heading-color) !important",
  color: theme.palette.common.white,
  fontSize: '1.5rem'
}));



let rows = [];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const ProjectApprovedMessages = (props) => {
  let { handleFileClose, seeApprovedMessages, nuggets, projectNugget, callApi, TabPanel } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(0);


  function Row(props1) {
    const { row, index } = props1;

    console.log("propspropsprops", row)
    const [open, setOpen] = React.useState(false);
    const backgroundColor = index % 2 === 1 ? '#eee' : 'transparent';

    return (
      <React.Fragment>
        <TableRow sx={{ backgroundColor, fontWeight: "bold" }}>
          <TableCell sx={{ fontSize: '1.5rem', padding: "4px 16px", color: "var(--sub-heading-color)", }}>{row.otitle}</TableCell>
          <TableCell sx={{ fontSize: '1.5rem', padding: "4px 16px", color: "var(--sub-heading-color)", }}>
            {
              row.projectData && row.projectData.length > 0 ? (
                <p style={{
                  width: "100%",
                  textAlign: "center",
                  borderRadius: "0.75rem",
                  border:
                    row.projectData[0].objStatus == 2
                      ? "2px solid"
                      : row.projectData[0].objStatus === "In-Progress"
                        ? "1px solid"
                        : row.projectData[0].objStatus === "Denied"
                          ? "1px solid"
                          : "",

                  borderColor:
                    row.projectData[0].objStatus == 2
                      ? "#005c0f"
                      : row.projectData[0].objStatus === "In-Progress"
                        ? "#b89247"
                        : row.projectData[0].objStatus === "Denied"
                          ? "#d61212"
                          : "",
                  backgroundColor:
                    row.projectData[0].objStatus == 2
                      ? "#f0ffec"
                      : row.projectData[0].objStatus === "In-Progress"
                        ? "#fffaf0"
                        : row.projectData[0].objStatus === "Denied"
                          ? "#fcf6f6"
                          : "",
                  color:
                    row.projectData[0].objStatus == 2
                      ? "#005c0f"
                      : row.projectData[0].objStatus === "In-Progress"
                        ? "#b89247"
                        : row.projectData[0].objStatus === "Denied"
                          ? "#d61212"
                          : "",
                }}>            {row.projectData[0].objStatus == 2 ? "Approved" : row.projectData[0].objStatus}</p>
              ) : (<p></p>)
            }
          </TableCell>
          <TableCell sx={{ fontSize: '1.5rem', padding: "4px 16px" }}>
            {
              row.projectData && row.projectData.length > 0 ? (<IconButton
                aria-label="expand row"
                onClick={() => setOpen(!open)}
                sx={{ color: 'var(--sub-heading-color)' }}
              >
                View message
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>) : (<></>)
            }

          </TableCell>
          <TableCell sx={{ fontSize: '1.5rem', padding: "4px 16px" }}>
            {
              row.projectData && row.projectData.length > 0 ? (
                <p>
                  {row.projectData[0].postedDate}
                </p>
              ) : (<p></p>)
            }

          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ backgroundColor: "#fafafa", padding: "0 1rem" }} colSpan={4}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <h4>Message :</h4>
                <Typography variant="body1" component="div" sx={{ fontSize: '1.2rem', margin: "1rem 0" }}>

                  {
                    row.projectData && row.projectData.length > 0 ? (<div>{row.projectData[0].message}
                      <div style={{ display: "flex", color: "var(--sub-heading-color)", gap: "0.1rem", alignItems: "center", cursor: "pointer" }}>
                        <p
                          onClick={() => {
                            console.log("row.oid", row.oid)
                            handleFileClose();
                            TabPanel(row.oid);
                            callApi(row.oid)
                          }}
                          style={{ textDecoration: "underline", fontSize: "14px", }}>
                          Visit Thread
                        </p>

                        <OpenInNewIcon style={{ height: "1.5rem" }} />
                      </div>
                    </div>) : (<></>)
                  }
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  Row.propTypes = {
    row: PropTypes.shape({
      serial: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      fileUploaded: PropTypes.string.isRequired,
      responseMessage: PropTypes.string.isRequired,
    }).isRequired,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    for (let i = 0; i < projectNugget.length; i++) {
      let temp = [];
      if (projectNugget[i].nid == newValue) {
        for (let j = 0; j < projectNugget[i].objects.length; j++) {
          for (let k = 0; k < seeApprovedMessages.length; k++) {
            if (projectNugget[i].objects[j].oid == seeApprovedMessages[k].oid) {
              temp.push(seeApprovedMessages[k]);

            }

          }
        }


        console.log("temp", temp)
        rows = temp

      }
    }

  };

  return (
    <div className={classes.mainDialog}>
      <div>
        <div style={{ display: "grid", gridTemplateColumns: "30% 70%", }}>
          <div style={{ margin: "2rem" }}>
            <h2 style={{ fontWeight: "bold" }}>Approved Message</h2>

            <div style={{ padding: "1rem 2rem", backgroundColor: "var(--sub-heading-color)", color: "white", margin: "2rem 2rem 2rem 0", fontSize: "2rem" }}>Project Phase</div>


            {Object.keys(nuggets).map((nugget, idx) => (
              <>
                {nuggets[nugget].map(
                  ({ nid, unit, ntitle, nduration, objects, nenabled }, idx) => (
                    <Box
                      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', }}
                    >
                      <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{
                          width: "100%", '& > div > span': {
                            display: "none!important",
                          },
                        }}
                      >
                        <Tab

                          className={classes.tabLabel}
                          // icon={<AccessTimeFilledIcon />}
                          key={nid}
                          label={
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                width: "100%",
                                justifyContent: "Space-between",
                                textTransform: "capitalize",
                                alignItems: "center",

                              }}
                            >
                              <p
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                  textAlign: "left",
                                }}
                              >
                                {ntitle}
                              </p>

                              {nenabled == false ||
                                (objects[0].userOP.op != 0 && (
                                  <AccessTimeFilledIcon
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      color: "#e35f14",
                                    }}
                                  />
                                ))}

                              {nenabled == false && (
                                <LockIcon
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    color: "gray",
                                  }}
                                />
                              )}



                            </div>
                          }
                          value={nid}
                          style={{
                            fontSize: "12px",
                            alignItems: "start",
                          }}
                        />
                      </Tabs>

                      {/* <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{
                          width: "100%", '& > div > span': {
                            display: "none!important",
                          },
                        }}
                      >
                        <Tab label="Project Planning Phase" {...a11yProps(0)} className={classes.tabLabel} icon={<AccessTimeFilledIcon />} />
                        <Tab label="Project Execution Phase" {...a11yProps(1)} className={classes.tabLabel} icon={<LockIcon />} />
                        <Tab label="Project Submission" {...a11yProps(2)} className={classes.tabLabel} icon={<LockIcon />} />
                      </Tabs> */}
                    </Box>
                  )
                )}
              </>
            ))}



          </div>

          <div style={{ position: "relative", borderLeft: "4px solid lightgray", height: "84vh" }}>
            <CloseIcon onClick={handleFileClose} style={{ position: "absolute", right: "1rem", top: "1rem", cursor: "pointer" }} />
            {/* <Box
              sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
            >
            </Box> */}

            <Grid item xs={12} sm={12} md={12} lg={12}>

              <TableContainer component={Paper} style={{ marginTop: "6rem" }}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Project Objective</StyledTableCell>
                      <StyledTableCell>Objective Status</StyledTableCell>
                      <StyledTableCell>Approved Message</StyledTableCell>
                      <StyledTableCell>Approval Date</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>


                    {rows != undefined || rows.length != undefined ? (<> {rows.map((row, index) => (
                      <Row key={row.oid} row={row} index={index} style={{
                        backgroundColor: index % 2 == 0 ? '#f0f0f0' : 'black',
                      }} />
                    ))}</>) : (<></>)


                    }

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>



          </div>
        </div>

      </div>
    </div>
  );
};

export default ProjectApprovedMessages;
